@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-color: #171821;
  --dark-grey: #21222D;

  --primary-font-color: #FFFFFF;
  --secondary-font-color: #87888C;

  --green: #A9DFD8;
  --pink: #F2C8ED;
  --orange: #FCB859;
  --blue: #28AEF3;
}

html {
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.status-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border: var(--secondary-font-color) solid 1px;
  border-radius: 10px;
  background-color: var(--dark-grey);
  opacity: 1;
  z-index: 99;
  left: -15px;
  top: -15px;
}

/*---------------------------------*/


.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: var(--green);
  border-radius: 50%;
  animation: spin 1s infinite linear;
  margin: 50px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*---------------------------------*/

.error-data-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

/*---------------------------------*/
/* Стилизация полос прокрутки для вебkit-браузеров */
::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
  height: 8px; /* Высота полосы прокрутки (для горизонтальной прокрутки) */
}

::-webkit-scrollbar-track {
  background: #1a1a1a; /* Цвет фона трека полосы прокрутки */
}

::-webkit-scrollbar-thumb {
  background-color: #4a4a4a; /* Цвет бегунка полосы прокрутки */
  border-radius: 10px; /* Скругление краев бегунка */
  border: 2px solid #1a1a1a; /* Отступ между бегунком и треком */
}

::-webkit-scrollbar-thumb:hover {
  background: #6a6a6a; /* Цвет бегунка при наведении */
}

/* Стилизация полос прокрутки для Firefox */
* {
  scrollbar-width: thin; /* Узкая полоса прокрутки */
  scrollbar-color: #4a4a4a #1a1a1a; /* Цвет бегунка и трека полосы прокрутки */
}

/* Дополнительно, чтобы применить стили только для темной темы,
вы можете обернуть их в медиа-запрос, если у вас есть переключатель тем */

@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #1a1a1a;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 10px;
    border: 2px solid #1a1a1a;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6a6a6a;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #4a4a4a #1a1a1a;
  }
}